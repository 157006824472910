const waited = new Map<any, { resolve(), reject() }>();

const observer = new window.IntersectionObserver((entries) => {
    for (const entry of entries) {
        if (entry.isIntersecting && entry.intersectionRatio > 0.01) {
            const w = waited.get(entry.target);
            if (w) {
                try {
                    w.resolve();
                } catch {}
            }
            observer.unobserve(entry.target);
        }
    }
}, {
    root: document.body
});

export default class ScreenApi {

    static async waitForVisible(target) {
        return new Promise<void>((resolve, reject) => {
            waited.set(target, { resolve, reject });
            observer.observe(target);
        });
    }

}
