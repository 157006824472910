import { sleep } from "./sleep";

const domReadyPromise = new Promise<void>((resolve, reject) => {

    if (document.readyState !== "loading") {
        // console.log(`dom is Ready`);
        setTimeout(() => {
            // console.log(`resolving setTimeout sent`);
            resolve();
        }, 1);
        // console.log(`setTimeout sent`);
        return;
    }

    const d = () => {
        document.removeEventListener("DOMContentLoaded", d);
        resolve();
    };
    document.addEventListener("DOMContentLoaded", d);
});

const done = Promise.resolve();
const prepared = () => done;

export const ComponentHelper = {

    waitForReady: async () => {

        await domReadyPromise;
        // console.log(`domReady`);
        await sleep(10);
    },

    prepareOnce(e: HTMLElement & { prepare(): Promise<void> }) {

        ComponentHelper.waitForReady().then(() => {
            const r = e.prepare().catch(console.error);
            Object.defineProperty(e, "prepare", { value: prepared });
            return r;
        }
        );

    },

    getParentAttribute(target: HTMLElement, map: (x: HTMLElement) => any) {
        if (!target) {
            return;
        }
        do {
            const value = map(target);
            if (value) {
                return { target, value };
            }
            target = target.parentElement;
        } while(target);
        return {};
    }

}