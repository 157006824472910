import { styled } from "../../core/core";
import HtmlControl from "../../core/HtmlControl";
import TemplateControl from "../../core/TemplateControl";
import { XNode } from "../../core/XNode";

    styled.css `
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    display: grid;

    &::part(root) {
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto 1fr auto;
        background-color: canvas;
        margin: auto;
        box-shadow: 0 0 6px 6px #c0c0c050;
        padding: 20px;
        border-radius: 10px;
    }

    &::part(title) {
        grid-column: 1 / span 2;
        grid-row: 1;
    }
    &::part(close) {
        grid-column: 3;
        grid-row: 1;
        cursor: pointer;
        box-sizing: border-box;
        margin: 10px;
        font: var(--fa-font-solid);
        font-size: 2rem;
        place-self: center;
        z-index: 2;
        &::after {
            content: "\uf057"
        }

    }

    &::part(content) {
        display: flex;
        flex-direction: column;
        grid-column: 1 / span 3;
        grid-row: 2;
    }

    
`.installGlobal("popup-window");

export default class PopupWindow extends TemplateControl {

    static observedAttributes = ["title"];

    root: ShadowRoot;

    titleElement: HTMLElement;

    async prepare() {
        const root = this.root = this.attachShadow({ mode: "open"});

        XNode.append(root, <section part="root">
            <h3 part="title"></h3>
            <i event-click={() => this.remove()} part="close" class="fas fa-close"/>
            <slot part="content"></slot>
        </section>);

        this.titleElement = root.querySelector("h3");
        this.titleElement.textContent = this.getAttribute("title");

    }
    onChildAdded(child: Element) {
        child.setAttribute("part", "content");
    }
    onChildRemoved(child: HTMLElement) {
        child.removeAttribute("part");
    }

    attributeChangedCallback(name, oldValue, newValue) {
        switch(name) {
            case "title":
                if (this.titleElement) {
                    this.titleElement.textContent = this.getAttribute("title");
                }
                break;
        }
    }



}

customElements.define("popup-window", PopupWindow);
